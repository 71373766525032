import React from 'react'
import Layout from '../components/layout'
import Header from '../components/header'
import Container from '../components/Container'
import SEO from '../components/seo'
import Box from '../components/Box'
import Hero from '../components/Hero'
import InputText from '../components/InputText'
import TextArea from '../components/TextArea'
import FormLabel from '../components/FormLabel'
import Button from '../components/Button'

const ContactFormPage = () => (
  <Layout>
     <SEO title='Contact' /> 
     <Header />
     <Hero title="Contact." />
    <Container as="main" id='main-content'>
      <form name="Contact Form" method="POST" data-netlify="true" action="/success">
        <InputText type="hidden" name="form-name" value="Contact Form" />
        <Box mt="4">
          <FormLabel>Your Email</FormLabel>
          <InputText type="email" name="email" />
        </Box>
        <Box>
          <FormLabel>Message</FormLabel>
          <TextArea name="message" />
        </Box>
        <Button variant="primary" type="submit">Send</Button>
      </form>
    </Container>
  </Layout>
)

export default ContactFormPage