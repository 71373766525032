import styled from 'styled-components'

const TextArea = styled.textarea`
    background-color: white;
    border: solid 2px #e5e5e5;
    border-radius: 0;
    color: #3A3F51;
    min-height: 96px;
    max-width: 100%;
    padding: 8px;
    width: 100%;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    :hover {
        border-color: #CECFD4;
    }

    :active,
    :focus {
        border-color: #4353FF;
        outline: 3px solid #D9DDFF;
        outline-offset: 0

    }
`


export default TextArea