// example Button with variants
import styled from 'styled-components'
import { color, space, fontSize, buttonStyle, borders,  boxShadow } from 'styled-system'

const Button = styled('button')(
  {
    appearance: 'none',
    fontFamily: 'inherit',
    textAlign: 'center',
    boxSizing: 'border-box',
    textDecoration: 'none',
    padding: '8px 16px',
    display: 'inline-block',
    cursor: 'pointer',
  },
  color,
  space,
  fontSize,
  buttonStyle,
  borders,
  boxShadow,
)

export default Button