import React from "react";
import Box from '../components/Box'
import Container from '../components/Container'
import Heading from '../components/Heading'


function Hero ( props ) {
  return (
  <Box as="section" my={['2', '4', '5']}>
    <Container>
      <Heading as="h1" fontSize={['5', '6', '7']} mb="3">{props.title}</Heading>
      {props.subtitle ? 
        <Heading as="h2" fontWeight="400" fontSize={['2', '3']} lineHeight='1.4' color="grey" mb="3">{props.subtitle}</Heading>
      : null } 
    </Container>
  </Box>
  ) 
}

export default Hero